export class orderUrls {
  onDemand = "demo.appdukaan.com:5014/v2";

  // Store Type
  storeType: string = `${this.onDemand}/admin/store/storeType`;
  bulkUploadStoreType: string = `${this.onDemand}/admin/store/bulkUploadStoreType`;
  exportStoreType: string = `${this.onDemand}/admin/store/getAllStoreTypeExport`;
  deleteSelectedStoreTypes: string = `${this.onDemand}/admin/store/deleteSelectedStoreTypes`;
  deleteAllStoreTypes: string = `${this.onDemand}/admin/store/deleteAllStoreTypes`;
  getMerchantOutlet: string = `${this.onDemand}/admin/store/outletforSingleVendor`;
  changeArrangingOrder: string = `${this.onDemand}/admin/store/changeArrangingOrder`;
  changeCategoriesOrder: string = `${this.onDemand}/admin/store/changeArrangingOrderCategory`;

  // Category
  category: string = `${this.onDemand}/admin/store/category`;
  bulkUploadCategory: string = `${this.onDemand}/admin/store/bulkUploadCategory`;
  exportCategory: string = `${this.onDemand}/admin/store/getAllCategoryExport`;
  deleteSelectedCategory: string = `${this.onDemand}/admin/store/deleteSelectedCategory`;
  deleteAllCategory: string = `${this.onDemand}/admin/store/deleteAllCategory`;
  getCategoriesByStoreType: string = `${this.onDemand}/admin/store/getCategoriesByStoreType`;
  getBrandsByStoreType: string = `${this.onDemand}/admin/store/getBrandsByStoreType`;
  getSubCategoriesByStoreType: string = `${this.onDemand}/admin/store/getSubCategoriesByStoreType`;

  // subCategory
  subCategory: string = `${this.onDemand}/admin/store/subCategory`;
  deleteAllSubCategory: string = `${this.onDemand}/admin/store/deleteAllSubCategory`;
  bulkUploadSubCategory: string = `${this.onDemand}/admin/store/bulkUploadSubCategory`;
  getAllSubCategoryExport: string = `${this.onDemand}/admin/store/getAllSubCategoryExport`;
  deleteSelectedStoreSubCategory: string = `${this.onDemand}/admin/store/deleteSelectedSubCategory`;
  getSubCategories: string = `${this.onDemand}/admin/store/getsubcategories`;

  // Brands
  brands: string = `${this.onDemand}/admin/store/brand`;
  deleteAllBrand: string = `${this.onDemand}/admin/store/deleteAllBrand`;
  deleteSelectedBrand: string = `${this.onDemand}/admin/store/deleteSelectedBrand`;
  getAllBrandExport: string = `${this.onDemand}/admin/store/GetAllBrandExport`;
  bulkUploadBrand: string = `${this.onDemand}/admin/store/bulkUploadBrand`;

  // Products
  products: string = `${this.onDemand}/admin/store/product`;
  itembyid: string = `${this.onDemand}/admin/store/itembyid`;
  variant: string = `${this.onDemand}/admin/store/variant`;
  deleteSelectedProduct: string = `${this.onDemand}/admin/store/deleteSelectedProduct`;
  deleteAllProduct: string = `${this.onDemand}/admin/store/deleteAllProduct`;
  getAllProductExport: string = `${this.onDemand}/admin/store/getAllProductExport`;
  bulkUploadProduct: string = `${this.onDemand}/admin/store/bulkUploadProduct`;
  getprototypeProducts: string = `${this.onDemand}/admin/store/getprototypeProducts`;

  // Driver
  driverOrder: string = `${this.onDemand}/admin/driver/getDriverAllOrders`;

  // Store
  store: string = `${this.onDemand}/admin/store`;
  storeById: string = `${this.onDemand}/admin/store/getStoreById`;
  getPendingStores: string = `${this.onDemand}/store/newStore`;
  getMerchantCategories: string = `${this.onDemand}/admin/store/getcategories`;
  getBrandsByStore: string = `${this.onDemand}/admin/store/getBrandsByStore`;
  getMerchantSubCategories: string = `${this.onDemand}/admin/store/getMerchantAllSubCategories`;
  getMerchantsFromType: string = `${this.onDemand}/admin/store/getStoresByType`;
  storeAllOrders: string = `${this.onDemand}/admin/store/orders/all`;
  deleteSelectedStores: string = `${this.onDemand}/admin/store/deleteSelectedStores`;
  deleteAllStores: string = `${this.onDemand}/admin/store/deleteAllStores`;
  addTimeSlot: string = `${this.onDemand}/admin/addTimeSlot`;
  getTimeSlots: string = `${this.onDemand}/admin/getTimeSlot`;
  getAllMarchantsCSV: string = `${this.onDemand}/admin/getAllMarchantsCSV`;
  getStoreDocuments: string = `${this.onDemand}/admin/store/document`;
  getAllStoreExport: string = `${this.onDemand}/admin/getAllStoreExport`;
  getDocument: string = `${this.onDemand}/store/getDocumentList`;

  bulkUploadStore: string = `${this.onDemand}/admin/bulkUploadStore`;

  getStoreInventory: string = `${this.onDemand}/admin/store/getStoreInventory`;
  updateCloneProducts: string = `${this.onDemand}/admin/store/updatecloneProducts`;
  getCatWithProdThroughStoreId: string = `${this.onDemand}/admin/store/updatecloneProducts`;
  serviceSlots: string = `${this.onDemand}/admin/store/serviceSlots`;
  blockCustomSlot: string = `${this.onDemand}/admin/store/customSlot`;

  // Order Manual Assignment
  getAllNearDrivers: string = `${this.onDemand}/admin/driver/getAllNearDrivers`;

  addVariantToInventory: string = `${this.onDemand}/admin/store/cloneProduct`;
  getStoreInventoryAll: string = `${this.onDemand}/admin/store/getStoreInventoryAll`;
  cloneAll: string = `${this.onDemand}/admin/store/cloneAll`;

  // Outlet
  outlet: string = `${this.onDemand}/admin/store/outlet`;

  // Order List
  getAllOrdersCSV: string = `${this.onDemand}/admin/getAllOrdersCSV`;
  getAllOrders: string = `${this.onDemand}/admin/store/orders/all`;
  bulkUploadOrder: string = `${this.onDemand}/admin/store/bulkUploadOrder`;
  getAllOrderExport: string = `${this.onDemand}/admin/store/getAllOrderExport`;
  assignDriverManually: string = `${this.onDemand}/admin/store/manuallyAssignOrder`;
  createOrderByAdmin: string = `${this.onDemand}/admin/createOrderByAdmin`;

  // User
  user: string = `${this.onDemand}/admin/user`;
  getUserAllOrders: string = `${this.onDemand}/admin/getAllOrdersCSV`;

  //Sales Person
  getStoresSP: string = `${this.onDemand}/admin/getStoresSP`;

  // Tickets
  Tickets: string = `${this.onDemand}/admin/tickets`;
  assignTickets: string = `${this.onDemand}/admin/store/assignTickets`;
  announceWinner: string = `${this.onDemand}/admin/store/winner`;

  // Campaigns
  campaign: string = `${this.onDemand}/admin/campaign`;
  getWinnerList: string = `${this.onDemand}/admin/store/getwinners`;

  // Add On's  Toppings
  AddOns: string = `${this.onDemand}/admin/addOns`;
  getAddOn: string = `${this.onDemand}/admin/getAddOn`;
  deleteAddOns: string = `${this.onDemand}/admin/deleteAddOns`;
  getToppingItems: string = `${this.onDemand}/admin/getToppingItems`;
  getPendingOrderCount: string = `${this.onDemand}/admin/pendingOrderCount`;
}
